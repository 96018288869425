@import "../node_modules/normalize.css";

.App {
  text-align: left;
}

.App-title {
  font-size: 2em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  margin: 0;
}

.SideBar {
  text-align: left;
  text-decoration: none;
  float: left;
  height: "100%";
  width: 220px;
}

.SideBarHeader h3 {
  text-decoration: none;
  color: black;
  font-size: 1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
}

.LoginForm {
  margin-top: 30px;
  padding: 30px;
  border-radius: 10px;
  background-color: #e8ecef;
}

.TransactionGrid {
  display: grid;
  grid-template-columns: auto auto;
  margin: 20px;
}

.TransactionGridLeft {
  border: 1px dashed #c9c9c9;
  border-right: "none";
}

.TransactionGridRight {
  border: 1px dashed #c9c9c9;
}

.MenuSection {
  padding-top: 20px;
}

.MenuSection h1 {
  text-decoration: none;
  color: black;
  font-size: 1.5em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
}

.SmallIcon:hover {
  opacity: 0.7;
  cursor: pointer;
}

.LoggedInHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-decoration: none;
  color: white;
  font-size: 1.25em;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
}

.LoggedInHeader a:hover {
  text-decoration: none;
}

.LoggedInHeader a:visited {
  color: white;
}

.LoggedInHeader h1 {
  padding: 0;
  margin: 0;
  font-size: 1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
}

.LoggedInHeader h2 {
  padding: 0;
  margin: 0;
  font-size: 1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
}

.ListHeader {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: bold;
}

.DropDownTitle {
  padding: 0;
  margin: 0;
  font-size: 1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
}

.DropDownTitle:hover {
  cursor: pointer;
  opacity: 0.8;
}

.CollapsingLineItem {
  cursor: pointer;
}

.ActiveCollapseItem h1 {
  text-decoration: none;
  color: black;
  font-size: 1.25em;
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
}

.ActiveCollapseItem h2 {
  text-decoration: none;
  color: black;
  font-size: 1em;
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
}

a:link {
  text-decoration: none;
  color: black;
  font-size: 1.25em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
}

a:visited {
  color: black;
}

.ErrorItem {
  text-decoration: none;
  color: "#721c24";
  font-size: 1.5em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
}

.SideBarHeader {
  text-align: left;
  font-size: 1.5em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 500;
  background-color: #e8ecef;
  border-bottom: #000000 2px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.ModuleBreadcrumb {
  background-color: #e8ecef;
  border-radius: 5px;
  padding: 2px;
  padding-left: 10px;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1.5em;
}

.SideBarModule {
  border-color: #e8ecef;
  border-width: 2px;
  border-style: solid;
  margin-bottom: 20px;
  border-radius: 3px;
}

.SideBarModuleLinks {
  padding: 5px;
}

.AppModuleText {
  flex: 1;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.AppModuleText h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.AppModuleText h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.ClassItem {
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border-color: #e8ecef;
  border-width: 2px;
  border-style: solid;
  padding: 10px;
  padding-bottom: 0;
}

.ClassItem h1 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  font-size: 1.75em;
}

.ClassItem h2 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 500;
  font-size: 1.25em;
  color: #303030;
}

.ClassItem h3 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1.25em;
}

.ClassItem h4 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 0.75em;
}

.ClassItem hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.InstructorItem {
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border-color: #e8ecef;
  border-width: 2px;
  border-style: solid;
  padding: 10px;
}

.InstructorItem h1 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  font-size: 1.75em;
}

.InstructorItem h2 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 500;
  font-size: 1.25em;
  color: #303030;
}

.EmptyListResponse {
  font-size: 2em;
  font-weight: 300;
}

.UserForm {
  border: 1px solid #d8d8d8;
  border-radius: 3pt;
}

.InfoText {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 500;
  font-size: 1.25em;
  color: #303030;
  text-align: center;
}

.BackButton {
  border: 1px solid #d8d8d8;
  background-color: #e8ecef;
  border-radius: 3pt;
  margin-bottom: 10px;
  padding: 5px;
}

.BackButton:hover {
  cursor: pointer;
  background-color: #d8d8d8;
}

.Hoverable:hover {
  cursor: pointer;
  opacity: 0.7;
}

.AppModule {
  border-radius: 10pt;
  display: "flex";
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-bottom: 10px;
}

.AppModuleItem {
  background-color: #f7f7f7;
  border: solid 2px #d7d8d9;
  padding: 10px;
  margin-top: 0;
  border-radius: 10px;
}

.AppModuleItem h1 {
  text-align: left;
  font-size: 2em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 500;
}

.AppModuleItem h2 {
  text-align: left;
  font-size: 1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 500;
}

.AppModuleItem h3 {
  text-align: left;
  font-size: 0.75em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: bold;
  color: #a3a3a3;
}

.AppModuleItem h4 {
  text-align: left;
  font-size: 0.75em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 500;
  color: #a3a3a3;
}

.AppModuleItem h5 {
  text-align: left;
  font-size: 1.25em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 500;
}

.SmallNote {
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 0.75em;
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: 500;
}

.SettingsContainer {
  border: 1px solid #d3d3d3;
  border-radius: 3px;
}

.SettingsContainer h1 {
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 1.25em;
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: 500;
}

.SettingsItem {
  border-bottom: 1px solid #d3d3d3;
  padding: 10px;
}

.SettingsItem h1 {
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 1.25em;
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: 500;
}

.TicketInfo {
}

.TicketInfo ul {
  list-style-type: none;
}

.SettingsItem h2 {
  padding: 0;
  margin: 0;
  padding-top: 5px;
  text-align: left;
  font-size: 1.25em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
}

.ModuleHeader {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.ModuleHeader h1 {
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 0.75em;
}

.ModuleHeader h2 {
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: 100;
  font-size: 1.5em;
}

.AppModuleSettings h1 {
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: 100;
  font-size: 1.5em;
}

.ModuleSettingsContainer {
  padding: 10px;
}

.ModuleSettingsContainer h1 {
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: 100;
  font-size: 1.25em;
}

.ListEmpty {
  padding-top: 10px;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  font-size: 1.25em;
  text-align: center;
  color: #303030;
}

.SettingsEmailList {
  padding-left: 20px;
  padding-top: 20px;
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: 300;
  font-size: 1.25em;
  text-align: center;
  color: #303030;
}

.SettingsEmailItem {
  padding-left: 5px;
}

.Notification {
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: 300;
  font-size: 1.25em;
  text-align: center;
  color: #303030;
}

.welcome {
  margin-top: 20px;
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: 300;
  font-size: 1.25em;
  text-align: center;
  color: #303030;
}

.welcome h1 {
  font-weight: 200;
}

.PreviewDigitalSign h2 {
  font-size: 1.5em;
}

.LargeModalButtonDark {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 90px;
  background-color: rgba(109, 117, 125, 1);
  border-radius: 10px;
  justify-content: center;
  margin-bottom: 10px;
  color: #ffffff;
  padding: 10px;
}

.LargeModalButtonDark h1 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1em;
  font-weight: bold;
}

.LargeModalButtonDark h2 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1em;
}

.LargeModalButtonDark:hover {
  cursor: pointer;
  background-color: rgba(109, 117, 125, 0.8);
}

.Note {
  background-color: #d9ecdb;
  border-radius: 5px;
  padding: 2px;
  text-align: center;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1.5em;
}

.EmailBadge {
  padding: 5px;
  border-radius: 5px;
  background-color: #f6f6f6;
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  border: solid 1px #a3a3a3;
}

.ActionBox {
  width: "50%";
  background: #f9f9f9;
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  padding: 30px;
}

.ActionBox h1 {
  font-weight: 300;
  font-size: 2em;
  text-align: center;
  margin-bottom: 30px;
}

.ActionBox h2 {
  font-size: 3em;
  text-align: center;
  color: #a3a3a3;
  margin-bottom: 30px;
}

.ProfileDropdown {
  width: 200pt;
  margin-top: 10pt;
  margin-left: -180pt;
  background-color: #261565;
  padding: 1px;
  text-align: left;
}

.ProfileDropdownHeader {
  padding: 5pt;
  background-color: #fefefe;
  color: #000000;
  border-bottom: 1px solid #000000;
  text-align: left;
  font-size: 1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
}

.ProfileDropdownHeader h1 {
  font-size: 0.75em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
}

.ProfileDropdownFooter {
  padding: 5pt;
  background-color: #fefefe;
  color: #000000;
  border-top: 1px solid #000000;
  text-align: left;
  font-size: 1vw;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
}

.ProfileDropdownItem {
  padding: 5pt;
  font-size: 1vw;
  background-color: #ffffff;
  color: #000000;
}

.ProfileDropdownItem:hover {
  background-color: #e8ecef;
  cursor: pointer;
}

.ProfileDropdownItem a {
  color: #000000;
}

.ProfileDropdownItem a:visited {
  color: #000000;
}

.SelectableButton {
  border-radius: 3px;
  border-width: 1px;
  border-color: #bdc2c9;
  border-style: solid;
  padding: 5px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
}

.SelectableButton:hover {
  background-color: #f8f9fa;
}

@import "components/SimpleEditor.scss";
@import "modules/sign/view/PreviewIpad.scss";
