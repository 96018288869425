$FONT_FAMILY: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif;

.LinkDecoration {
  cursor: pointer;
  display: inline;

  &__text {
    background: #eef8ff;
    border-bottom: 1px dotted #000;

    &:hover {
      border-bottom: 1px solid #000;
    }
  }
}

.LinkSource {
  &__text {
    font-family: $FONT_FAMILY;
    font-size: larger;
    margin-right: 10px;
  }
}

.ImageBlock {
  &__image {
    &:hover {
      cursor: pointer;
    }
  }
  &__altText {
    font-family: $FONT_FAMILY;
    font-size: smaller;
    margin-right: 10px;
  }
}

.LinkDelete {
  cursor: pointer;
  background: #eef8ff;
  border: 1px dotted #000;
  width: 15px;
  height: 15px;
  border-radius: 15px;

  &:hover {
    background: #ffffff;
    border: 1px solid #000;
  }
}

$WHITE: #fff;

$FONT_FAMILY_SANS: system-ui, -apple-system, BlinkMacSystemFont,
  "Helvetica Neue", Helvetica, Arial, sans-serif;

$draftail-editor-chrome: #303030;
$draftail-editor-background: $WHITE;
$draftail-editor-chrome-text: $WHITE;
$draftail-editor-chrome-active: $WHITE;
$draftail-editor-chrome-accent: lighten($draftail-editor-chrome, 20%);

$draftail-editor-font-family: $FONT_FAMILY_SANS;
@import "../../node_modules/draft-js/dist/Draft";
@import "../../node_modules/draftail/lib/index";

.Draftail-block--header-one {
  font-size: 1.5rem;
}

.Draftail-block--header-two {
  font-size: 1.2rem;
}

.public-DraftEditor-content {
  min-height: 300px;
  max-height: 400px;
  overflow: auto;
  position: relative;
}

.noteText {
  font-family: $FONT_FAMILY;
  font-size: 0.75em;
}
