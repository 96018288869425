.PreviewBasic {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  color: "red";
}

.PreviewBasic img {
  max-width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.imageFade-entering {
  opacity: 0;
}
.imageFade-entered {
  opacity: 1;
  transition: opacity 0.6s;
}

.imageFade-exiting {
  opacity: 1;
}
.imageFade-exited {
  opacity: 0;
  transition: opacity 0.6s;
}

.CenteredImage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
